<template>
  <div>
    <div
      class="white pt-6 pb-6 pl-8 pr-8 mb-5 d-flex justify-space-between align-center rounded-lg"
    >
      <h1 class="primary--text font-weight-black">INTERNET MÓVIL</h1>
      <v-btn
        class="font-weight-regular"
        large
        depressed
        color="primary"
        @click="$emit('purchase')"
        >PONER PLAN</v-btn
      >
    </div>

    <div
      class="white primary--text font-weight-black pt-4 pb-4 pl-8 pr-8 d-flex align-center rounded-lg"
    >
      <div class="info--date">FECHA</div>
      <div class="info--name">NOMBRE</div>
      <div class="info--validity text-center">VIGENCIA</div>
      <div class="info--price text-center">MONTO</div>
      <div class="info--status text-center">ESTATUS</div>
      <div class="info--actions text-center">ACCIONES</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InternetMovilTableHead",
  data: () => ({
    component: "InternetMovilPurchase",
  }),
  methods: {
    prueba() {
      this.$emit("purchase");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>